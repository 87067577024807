(function( window ){
	
	'use strict';
	
	//
	// constructor
	//
	function Popup( content, extraClass, events ) {
		
		this._events	= events || {};
		
		build.call( this, content, extraClass );
		
	}
	
	// expose globally
	// TODO	`createPopup` interface as the public entrypoint
	window[ 'Popup' ]	= Popup;
	
	
	//
	// private methods
	//
	
	function build( content, extraClass ) {
		
		// build the std. elements (wrapper/bg and container)
		//
		// TODO	close button? I know it closes on outside click but only
		//		having that is bad UX
		
		this.wrapper	= document.createElement( 'div' );
		this.element	= document.createElement( 'div' );
		
		this.wrapper.className	= extraClass
									? 'popup--wrapper ' + extraClass
									: 'popup--wrapper';
		
		this.element.className	= 'popup';
		
		
		// build the specific content
		
		// DOM content
		if ( content.appendChild ) {
			
			this.element.appendChild( content );
			
		// string content
		} else if ( content.split ) {
			
			this.element.insertAdjacentHTML( 'beforeend', content );
			
		} else {
			
			console.error( 'Invalid popup content:', content );
			throw new Error( 'Invalid popup content' );
			
		}
		
		
		this.wrapper.appendChild( this.element );
		
		bindOutsideClickCloser.call( this );
		
	}
	
	function positionOnScreen() {
		
		//this.element.style.top	= ( window.scrollY + 160 ) + 'px';
		
	}
	
	
	function bindOutsideClickCloser() {
		
		let	self	= this;
		
		// store the handlers so that they can be unbound
		this._closers	= {
							one	: function() {
									
									self.close();
									
								},
							two	: function( evt ) {
									
									evt.stopPropagation();
									
								}
						};
		
		// bind to `this.wrapper` so we can close onClick
		this.wrapper.addEventListener(
			'click',
			this._closers.one
		);
		
		// bind to `this.element` so we can intercept the clicks which we don't want closing it
		this.element.addEventListener(
			'click',
			this._closers.two
		);
		
	}
	
	function unbindOutsideClickCloser() {
		
		this.wrapper.removeEventListener(
			'click',
			this._closers.one
		);
		
		this.element.removeEventListener(
			'click',
			this._closers.two
		);
		
	}
	
	function remove() {
		
		this.wrapper.parentNode && this.wrapper.parentNode.removeChild( this.wrapper );
		
	}
	
	
	//
	// public methods
	//
	
	function open() {
		
		let	self	= this;
		
		// N.B.	by appending before all other content we can change
		//		their style with the `~` selector if needs be
		document.body.insertBefore( this.wrapper, document.body.children[ 0 ] );
		
		positionOnScreen.call( this );
		
		this._events.open && this._events.open.call( this );
		
		// timeout to allow transition to trigger
		setTimeout(
			function() {
				
				self.wrapper.classList.add( 'open' );
				
			},
			50
		);
		
	}
	
	Popup.prototype.open	= open;
	
	
	function close() {
		
		let	self	= this;
		
		// unbind events to (try to) avoid memory leaks
		// TODO	need to find a nicer way to unbind any
		//		events bound to the popup's content
		unbindOutsideClickCloser.call( this );
		
		this._events.beforeClose && this._events.beforeClose.call( this );
		
		this.wrapper.addEventListener(
			'transitionend',
			function() {
				
				// TODO	need a fallback in case there's no transition defined
				
				remove.call( self );
				
				self._events.afterClose && self._events.afterClose.call( self );
				
			}
		);
				
		this.wrapper.classList.remove( 'open' );
		
	}
	
	Popup.prototype.close	= close;
	
	
})( window );
