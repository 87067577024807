(function( window ) {

	'use strict';


	document.addEventListener( 'DOMContentLoaded', domReady );

	function domReady() {

		Array.prototype.slice.apply(
			document.querySelectorAll( '.JS_showmore' )
		).forEach(
			function( btn ) {

				btn.addEventListener( 'click', showMoreClick );

			}
		);

	}

	function showMoreClick( evt ) {

		let	btn	= this,

			offset	= btn.getAttribute( 'data-offset' ) * 1 || 0,
			count	= btn.getAttribute( 'data-count' ) * 1 || 15;


		btn.classList.add( 'loading' );
		btn.setAttribute( 'disabled', 'disabled' );


		jQuery.get(
			'/blog/pagination/',
			{
				offset		: offset,
				count		: count,
				category	: btn.getAttribute( 'data-category' ) || null,
				relatedTo	: btn.getAttribute( 'data-relatedTo' ) || null,
			}
		).done(
			function( html ) {

				// TODO	what if there's more than one?
				document.querySelector( '.JS_showmore-target' ).insertAdjacentHTML(
					'beforeend',
					html
				);

				btn.setAttribute( 'data-offset', offset + count );

				var frag2 = new DOMParser().parseFromString(html, "text/html");
				var acount = frag2.querySelectorAll('.post-card').length

				if ( acount < count ) {
					btn.style.display	= 'none';
				}

			}
		).fail(
			function( err ) {

				console.error( err );

			}
		).always(
			function() {

				btn.classList.remove( 'loading' );
				btn.removeAttribute( 'disabled' );

			}
		);

	}


	function showReturnedHTML( ret ) {

	}

})( window );
