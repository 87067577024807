////////////////////////////////////////////////////////////////////////////////
// Navigation
////////////////////////////////////////////////////////////////////////////////

let navigation = $('navigation-bar');

navigation.find('button.menu').on('click', function(event) {
  event.preventDefault();
  navigation.toggleClass('open')
})
