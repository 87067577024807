(function( window ) {
	
	'use strict';
	
	
	document.addEventListener(
		'DOMContentLoaded',
		function() {
			
			bindEmailButtons();
			
		}
	);
	
	function bindEmailButtons() {
		
		let	btns	= document.querySelectorAll( '.JS_email-member-btn' );
		
		Array.prototype.slice.call( btns ).forEach(
			bindEmailButton
		);
		
	}
	
	function bindEmailButton( btn ) {
		
		btn.addEventListener(
			'click',
			emailButtonClick
		);
		
	}
	
	function emailButtonClick() {
		
		let	formWrap	= document.querySelector( '.JS_email-form-src' ).children[ 0 ],
			pop			= new Popup(
							formWrap,
							null,
							{
								afterClose	: function() {
												
												// move the form back to its hidden lair
												document.querySelector( '.JS_email-form-src' ).appendChild( formWrap );
												
												// wipe the form's fields
												formWrap.querySelector( 'form' ).reset();
												
												// remove success/error messages so they'll be gone if it's reopened
												let	msg	= formWrap.querySelector( '.ff-form-success,.ff-form-errors' );
												
												if ( msg ) {
													
													msg.parentNode && msg.parentNode.removeChild( msg );
													
												}
												
											}
							}
						);
		
		pop.open();
		
		// http://docs.solspace.com/craft/freeform/v2/templating-extras/ajax-forms.html
		formWrap.addEventListener(
			'form.submit.success',
			function( evt ) {
				
				pop.element.innerHTML	= '<h2>Enquiry Sent!</h2>'
										+ '<p>This popup should close in a couple of seconds</p>';
				
				setTimeout(
					function() {
						
						pop.close();
						
					},
					2500
				);
				
			}
		);
		
	}
	
})( window );
