(function( globals ) {
	
	'use strict';
	
	const	logo	= document.querySelector( '.JS_memberlogo' ),
			info	= document.querySelector( '.JS_memberlogo-info' ),
			pre		= document.querySelector( '.JS_memberlogo-code' ),
			
			termsBox	= document.querySelector( '.JS_logotermsbox' );
	
	// prevent right clicks on logo in a vain attempt to stop users downloading it
	logo && logo.addEventListener(
		'contextmenu',
		function( evt ) {
			
			evt.preventDefault();
			
			//info.classList.toggle( 'JS_memberlogo-info--open' );
			
			return	false;
			
		}
	);
	
	logo && logo.addEventListener(
		'click',
		function( evt ) {
			
			//info.classList.toggle( 'JS_memberlogo-info--open' );
			
		}
	);
	
	// handle sending off the terms of use acceptance
	termsBox && termsBox.addEventListener(
		'change',
		function( evt ) {
			
			if ( termsBox.checked ) {
				
				jQuery.get('/membership/logo-terms?accept').then(
					function( res ) {
						
						info.classList.add( 'JS_memberlogo-info--open' );
						
					}
				);
				
			} else {
				
				jQuery.get('/membership/logo-terms').then(
					function( res ) {
						
						info.classList.remove( 'JS_memberlogo-info--open' );
						
					}
				);
				
			}
			
		}
	);
	
	// initial state
	if ( termsBox && termsBox.checked ) {
		
		info && info.classList.add( 'JS_memberlogo-info--open' );
		
	} else {
		
		info && info.classList.remove( 'JS_memberlogo-info--open' );
		
	}
	
	
	// clicking the image code should select it all
	pre && pre.addEventListener(
		'click',
		function( evt ) {
			
			const	selection	= document.getSelection();
			
			selection.selectAllChildren( pre )
			
		}
	);
	
})( window )
